import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"

import {
  Typography,
  makeStyles,
  Grid,
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core"

import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  // heading: {
  //   textAlign: "center",
  //   marginBottom: "1rem",
  //   color: "#033f96",
  //   fontSize: "2.7rem",
  // },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  map: {
    "& iframe": {
      width: "100% !important",
    },
  },
}))

// const fairs_data = [
//   {
//     title: "Fair Name",
//     date: "20/08/2020",
//     suite: "8",
//     address: "Fatih, istanbul, Turkey",
//     books: [
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//     ],
//     contact: "00905123215489",
//     map:
//       '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219807.35684226977!2d29.003928567221177!3d41.06169141073229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa7040068086b%3A0xe1ccfe98bc01b0d0!2zxLBzdGFuYnVs!5e0!3m2!1sen!2str!4v1611138467925!5m2!1sen!2str" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
//   },
//   {
//     title: "Fair Name 2",
//     date: "20/09/2020",
//     suite: "8",
//     address: "Fatih, istanbul, Turkey",
//     books: [
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//     ],
//     contact: "00905123215489",
//     map:
//       '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219807.35684226977!2d29.003928567221177!3d41.06169141073229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa7040068086b%3A0xe1ccfe98bc01b0d0!2zxLBzdGFuYnVs!5e0!3m2!1sen!2str!4v1611138467925!5m2!1sen!2str" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
//   },
//   {
//     title: "Fair Name 3",
//     date: "20/10/2020",
//     suite: "8",
//     address: "Fatih, istanbul, Turkey",
//     books: [
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//     ],
//     contact: "00905123215489",
//     map:
//       '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219807.35684226977!2d29.003928567221177!3d41.06169141073229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa7040068086b%3A0xe1ccfe98bc01b0d0!2zxLBzdGFuYnVs!5e0!3m2!1sen!2str!4v1611138467925!5m2!1sen!2str" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
//   },
//   {
//     title: "Fair Name 4",
//     date: "18/11/2020",
//     suite: "8",
//     address: "Fatih, istanbul, Turkey",
//     books: [
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//     ],
//     contact: "00905123215489",
//     map:
//       '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219807.35684226977!2d29.003928567221177!3d41.06169141073229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa7040068086b%3A0xe1ccfe98bc01b0d0!2zxLBzdGFuYnVs!5e0!3m2!1sen!2str!4v1611138467925!5m2!1sen!2str" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
//   },
//   {
//     title: "Fair Name 5",
//     date: "07/12/2020",
//     suite: "8",
//     address: "Fatih, istanbul, Turkey",
//     books: [
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//       {
//         id: Math.random(),
//         title: "Book Title",
//       },
//     ],
//     contact: "00905123215489",
//     map:
//       '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219807.35684226977!2d29.003928567221177!3d41.06169141073229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa7040068086b%3A0xe1ccfe98bc01b0d0!2zxLBzdGFuYnVs!5e0!3m2!1sen!2str!4v1611138467925!5m2!1sen!2str" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
//   },
// ]

const BooksFairs = ({ pageContext }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { pLang } = pageContext

  const [expanded, setExpanded] = useState(false)
  const [fairsData, setFairsData] = useState()

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "books-fairs",
        `/${i18n.language}/books-fairs`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "books-fairs",
          `/${i18n.language}/books-fairs`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/books-fairs`
      }
    })
  }, [i18n, pLang])

  const breadcrumbs = [
    {
      text: t("booksFairs.booksFairsTitle"),
    },
  ]

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/exhibitions`)
      .then(res => setFairsData(res.data))
  }, [])

  return (
    <>
      <SEO title={t("booksFairs.booksFairsTitle")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("booksFairs.booksFairsTitle")} />
      <Container className={classes.mainContainer}>
        {/* <div dangerouslySetInnerHTML={{__html: item.map}}/> */}
        {fairsData && fairsData.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === "panel" + index + 1}
            onChange={handleChange("panel" + index + 1)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.heading}>
                    {i18n.language === "ar" ? item.title_ar : i18n.language === "en" ? item.title_en : i18n.language === "tr" && item.title_tr}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.secondaryHeading}>
                    {item.date}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Typography component="p" style={{ marginBottom: 5 }}>
                    {t("booksFairs.suiteNumber")} {item.number}
                  </Typography>
                  <Typography component="p" style={{ marginBottom: 5 }}>
                    {t("booksFairs.address")} {item.address}
                  </Typography>
                  <Typography component="p" style={{ marginBottom: 5 }}>
                    {t("booksFairs.contact")} {item.phone}
                  </Typography>
                  <Typography component="p" style={{ marginBottom: 10 }}>
                    {t("booksFairs.books")} {item.book}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        {item.books.map(book => (
                          <TableRow key={book.id}>
                            <TableCell component="th" scope="book">
                              {book.title}
                            </TableCell>
                            <TableCell align="right"> </TableCell>
                            <TableCell align="right"> </TableCell>
                            <TableCell align="right"> </TableCell>
                            <TableCell align="right"> </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography component="p" style={{ marginBottom: 10 }}>
                    {t("booksFairs.map")}
                  </Typography>
                  <div
                    className={classes.map}
                    dangerouslySetInnerHTML={{ __html: item.location }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
      {/* <Grid container className={classes.fullWidth}> */}
      {/* <Grid item xs={1} md={3}></Grid> */}
      {/* <Grid item xs={10} md={10}>
          <Typography variant="h3" component="h3" className={classes.heading}>
            {t("booksFairs.booksFairsTitle")}
          </Typography>
        </Grid> */}
      {/* <Grid item xs={1} md={3}></Grid> */}
      {/* </Grid> */}
    </>
  )
}

export default BooksFairs
